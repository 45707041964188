import { httpRequests } from "../../../helpers/HttpRequests"

export function floorsServices() {
    const { getRequest, postRequest, putRequest, httpErrors } = httpRequests()
    const errors = httpErrors

    function addSingleFloorService(floor) {
        return postRequest('/web/floors/add/single', floor)
    }

    function addMultipleFloorsService(floors) {
        return postRequest('/web/floors/add/multiple', floors)
    }

    function editFloorService(floorId) {
        return getRequest(`/web/floors/edit/${floorId}`)
    }

    function updateFloorService(floorId, floor) {
        return putRequest(`/web/floors/update/${floorId}`, floor)
    }

    return { addSingleFloorService, addMultipleFloorsService, editFloorService, updateFloorService, errors }
}
