<template>
    <form class="form" @submit.prevent="addFloors">
        <div class="form__inputs-content">
            <div class="form__input-form">
                <label class="form__input-label form--required">From Floor</label>
                <input type="number" placeholder="From Floor" class="form__input" v-model.number="floors.startFloor">

                <div class="form__input-error" v-if="errors.fields.startFloor">
                    {{ errors.fields.startFloor[0] }}
                </div>
            </div>
            <div class="form__input-form">
                <label class="form__input-label form--required">To Floor</label>
                <input type="number" placeholder="To Floor" class="form__input" v-model.number="floors.endFloor">

                <div class="form__input-error" v-if="errors.fields.endFloor">
                    {{ errors.fields.endFloor[0] }}
                </div>
            </div>
            <div class="form__input-form">
                <label class="form__input-label">Floor Prefix (Max 5 Characters)</label>
                <input type="text" placeholder="Example B Or Lobby..." class="form__input" v-model="floors.prefix">

                <div class="form__input-error" v-if="errors.fields.prefix">
                    {{ errors.fields.prefix[0] }}
                </div>
            </div>
        </div>

        <div class="form__buttons">
            <button class="button button--success" v-if="!buttonLoader">
                <img src="@/assets/icons/vuesax/linear/add-circle.svg" alt="">
                Add Floors
            </button>

            <ButtonTextLoader button-class="button button--success button-text-loader--content-width"
                loader-text="Verifying Information" loader-class="button-text-loader__loader-primary"
                :is-loading="buttonLoader" />
        </div>
    </form>

    <Notifications ref="notification" />
</template>

<script setup>
import { reactive, ref } from 'vue'
import { floorsServices } from '../Services/FloorsServices.js'
import Notifications from '../../../common/Notifications.vue'
import { sweetAlerts } from '../../../helpers/SweetAlert.js'
import { useRouter } from 'vue-router'
import ButtonTextLoader from '../../../common/ButtonTextLoader.vue'

const notification = ref(null);
const { addMultipleFloorsService, errors } = floorsServices()
const { sweetSuccess } = sweetAlerts()
const router = useRouter()
const buttonLoader = ref(false)
const floors = reactive({
    startFloor: '',
    endFloor: '',
    prefix: ''
});

async function addFloors() {
    // * Show button loader
    buttonLoader.value = true

    const response = await addMultipleFloorsService(floors)

    if (response.hasErrors) {
        buttonLoader.value = false
        return notification.value.sendNotification('error', 'Error', response.message)
    }

    router.push('/hotel_setup/floors')

    return sweetSuccess(response.data.message)
}
</script>