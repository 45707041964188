<template>
    <div class="radio-inputs radio-inputs--row">
        <div class="radio-inputs__radio">
            <input type="radio" id="single" checked name="floor-option" @change="selectedForm(SingleFloorsFormAddComponent)">
            <label for="single">Add Single Floor</label>
        </div>

        <div class="radio-inputs__radio">
            <input type="radio" id="multiple" name="floor-option" @change="selectedForm(MultipleFloorsFormAddComponent)">
            <label for="multiple">Add Multiple Floors</label>
        </div>
    </div>
</template>

<script setup>
import SingleFloorsFormAddComponent from './SingleFloorsFormAddComponent.vue'
import MultipleFloorsFormAddComponent from './MultipleFloorsFormAddComponent.vue'
import { onMounted } from 'vue'

const emit = defineEmits(['selectedForm'])

onMounted(() => {
    emit('selectedForm', SingleFloorsFormAddComponent)
})

function selectedForm(formComponent) {
    emit('selectedForm', formComponent)
}
</script>